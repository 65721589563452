import { theme } from '@fountain/fountain-ui-components';
import { useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route as PublicRoute,
  Switch,
  useLocation,
} from 'react-router-dom';

import RootContainer from 'components/RootContainer/Loadable';
import Account from 'containers/Account';
import ApplicantTableStyle from 'containers/App/ApplicantTableStyle';
import AppGlobalStyle from 'containers/App/GlobalStyle';
import {
  makeSelectAccountSlug,
  makeSelectFirstJobId,
  makeSelectIsFetchingWhoami,
  makeSelectIsLoggedIn,
} from 'containers/Auth_old/selectors';
import AuthRoute from 'containers/AuthRoute';
import CCPA from 'containers/CCPA';
import FlashMessage from 'containers/FlashMessage';
import LandingPage from 'containers/LandingPage';
import NotFoundPage from 'containers/NotFoundPage/Loadable';

import { domainMeta } from '../../utils/domainMeta';

export default function App() {
  const location = useLocation();

  const { pathname } = location;

  const isLoggedIn = useSelector(makeSelectIsLoggedIn());
  const isLoading = useSelector(makeSelectIsFetchingWhoami());
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const accountSlug = useSelector(makeSelectAccountSlug());
  const jobId = useSelector(makeSelectFirstJobId());
  const applicantTableV2Url = `/${accountSlug}/jobs/${jobId}/v2/stages?page=1`;

  const getRedirectUrl = () => {
    if (mobileDevice) return applicantTableV2Url;

    return `/${accountSlug}/openings`;
  };

  useEffect(() => {
    if (window.Appcues) {
      window.Appcues.page();
    }
  }, [pathname]);

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page();
    }
  }, [location]);

  return (
    <RootContainer>
      <Helmet titleTemplate="%s | Fountain" defaultTitle="Talent at Fountain">
        <meta name="description" content="Find talent at Fountain" />
      </Helmet>
      <Helmet>
        {domainMeta.environment === 'prod' && (
          <script
            src="https://static.fountain.com/appcues.js"
            integrity="sha384-3KrBWQfeZs0R4cmNk78w5iYxPxpy3HxnMUxuRgAioPz8U8pRBzsNoNOYjCrK6kdE"
            crossOrigin="anonymous"
          ></script>
        )}
      </Helmet>
      <Helmet>
        {domainMeta.environment === 'prod' && (
          <script>
            {`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdp.customer.io/v1/analytics-js/snippet/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._writeKey=key;analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.15.3";
                analytics.load("6e09fd47230ebcc765df");
                analytics.setAnonymousId();
                analytics.page();
              }}();`}
          </script>
        )}
      </Helmet>
      <Switch>
        <PublicRoute path="/landing" component={LandingPage} />
        <PublicRoute path="/ccpa" component={CCPA} />

        {/* Handle redirect from '/:accountSlug' */}
        {!isLoading && isLoggedIn && (
          <Redirect from="/:accountSlug" exact to={getRedirectUrl()} />
        )}
        {/* Handles all authenticated routes under /:accountSlug */}
        <AuthRoute path="/:accountSlug" component={Account} />
        {/* Handle redirect from '/'
            Let LandingPage handle redirect to applicantTableV2Url */}
        <Redirect from="/" exact to="/landing" />
        <PublicRoute component={NotFoundPage} />
      </Switch>
      <AppGlobalStyle />
      <ApplicantTableStyle theme={theme} />
      <FlashMessage />
    </RootContainer>
  );
}
